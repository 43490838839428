import React, { useState } from 'react';

// IMPORT IMAGES =======================================>

import white_logo from '../images/Logo-white.png';
import chef_image from '../images/chef-portrait-image.png';
import mockup_macbook_iphone from '../images/mockup-macbook-iphone.png';
import apresentacao_menu_digital_qr_code from '../images/apresentacao-menu-digital-qr-code.PNG';
import apresentacao_hud_iphone from '../images/apresentacao-hud-iphone.PNG';
import mockup_demo_menu_hud from '../images/mockup-demo-menu-hud.png';
import white_favicon from '../images/Favicon-white.png';

// STYLE LINKS
import '../css/screens/home/home.css';

// IMPORT ICONS
import { IoLogoAndroid, IoIosClose } from 'react-icons/io';
import { FaApple, FaWhatsapp } from 'react-icons/fa';
import { TbWorldWww } from 'react-icons/tb';
import { RxLapTimer } from 'react-icons/rx';
import { BiGroup, BiMoneyWithdraw, BiNotepad, BiUserPin, BiUniversalAccess } from 'react-icons/bi';
import { IoBarChartOutline } from 'react-icons/io5';
import { CiMail } from 'react-icons/ci';
import { FaBars } from 'react-icons/fa6';

function Home() {
    const [openMenu, setOpenMenu] = useState(false);

    document.addEventListener('scroll', () => {
        const navbar = document.getElementById('navbar');
        const navbarContent = document.getElementById('navbar-content');

        const scrollY = window.scrollY;

        if (window.screen.width > 768) {
            if (scrollY > 500) {
                navbar.style.width = '90%';
                navbar.style.position = 'fixed';
                navbar.style.left = '50%';
                navbar.style.transform = 'translate(-50%, 0)';
                navbar.style.top = 0;
                navbar.style.boxShadow = '0 2px 5px #333';
                navbar.style.borderBottomLeftRadius = '20px';
                navbar.style.borderBottomRightRadius = '20px';

                navbarContent.style.width = '90%';
            } else {
                navbar.style.width = '100%';
                navbar.style.position = 'relative';
                navbar.style.top = 0;
                navbar.style.boxShadow = 'none';
                navbar.style.borderRadius = '0';

                navbarContent.style.width = '80%';
            }

        } else {
            if (scrollY > 500) {
                navbar.style.position = 'fixed';
                navbar.style.top = 0;
                navbar.style.boxShadow = '0 2px 5px #333';
            } else {
                navbar.style.position = 'relative';
                navbar.style.top = 0;
                navbar.style.boxShadow = 'none';
            }
        }
    });


    // SCREEN ==============================================================>
    return (
        <div className="screen">
            <header className="navbar" id="navbar">
                <div className="navbar-content" id="navbar-content">
                    <img src={white_logo} className="navbar-logo" />

                    <div className="navbar-links-area">
                        <ul className={openMenu ? 'navbar-links active' : 'navbar-links'}>
                            <li className="link">
                                <a href='#menu'>
                                    <span>Início</span>
                                </a>
                            </li>
                            <li className="link">
                                <a href='#about-us'>
                                    <span>Sobre nós</span>
                                </a>
                            </li>
                            <li className="link">
                                <a href='#contact'>
                                    <span>Contato</span>
                                </a>
                                
                            </li>
                            <li className="link" id='login-button'>
                                <a href='https://app.sofchef.com.br'>
                                    <span>Entrar</span>
                                </a>
                                
                            </li>
                        </ul>

                        <button className="menu-button" onClick={() => setOpenMenu(!openMenu)}>
                            {!openMenu ? (
                                <FaBars size={40} color="#fff"/>
                            ) : (
                                <IoIosClose size={50} color='#fff'/>
                            )}
                        </button>
                    </div>
                </div>
            </header>

            {/* TOP BANNER ÁREA */}
            <div className="initial-banner" id='menu'>
                <div className="initial-banner-text-area">
                    <p className="text-initial-banner">
                        A solução que vai mudar seu <br />
                        jeito de controlar seu <b>negócio</b>
                    </p>
                </div>
                <div className="initial-banner-image-area">
                    <img className="initial-banner-image" src={chef_image} />
                </div>
            </div>

            {/* CONTAINER: EXPLICAÇÃO DA PLATAFORMA */}

            <section className="divided-container" id="explain-platform-section">
                <div className="divided-area" id="explain-platform-images">
                    <img className="image-explain-platform" src={mockup_macbook_iphone} />
                </div>
                <div className="divided-area" id="explain-platform-text-area">
                    <h1 className="title-explain-platform">multi-plataforma</h1>

                    <p className="text-explain-platform">
                        Apresentamos um sistema totalmente online executado na nuvem, por qualquer tipo de celular, tablet ou computador desktop de onde você estiver localizado! <br />
                        <br />
                        <b>Com poucos recursos você administra seu negócio.</b>
                    </p>

                    <div className="explain-platform-icons-area">
                        <IoLogoAndroid className="explain-platform-icons" size={60} color="green" />
                        <FaApple className="explain-platform-icons" style={{ padding: 15 }} size={55} color="black" />
                        <TbWorldWww className="explain-platform-icons" size={60} color="black" />
                    </div>
                </div>
            </section>

            {/* CONTAINER: EXPLICAÇÃO MENU DIGITAL */}
            <section className="divided-container" id="menu-digital-section">
                <div className="divided-area" style={{ flexDirection: 'column', alignSelf: 'flex-start', marginTop: 30 }}>
                    <div id="explain-menu-text-area">
                        <h1 className="subtitle-explain-menu">Menu digital</h1>
                        <h1 className="title-explain-menu">QR-Code</h1>

                        <p className="text-explain-menu">
                            O mundo está em constante evolução e seu negócio <b>precisa</b> acompanhar <b>esse movimento</b>.<br />
                            <br />
                            Um menu <b>totalmente digital</b> para mostrar seus produtos e realizar pedidos será a solução que você está precisando para aumentar a experiência dos seus clientes e <b>levar seu negócio a um novo patamar</b>!
                        </p>
                    </div>
                </div>
                <div className="divided-area presentation-digital-menu-image">
                    <div className="image-present-digital-menu" />
                </div>
            </section>

            <section className="statics-cards-area">
                <h1 className="title-statics">Totalmente seguro e confiável:</h1>

                <div id="sphere-end-title-statics"></div>

                <div className="cards-area">
                    <div className="card">
                        <RxLapTimer size={60} color="#000" />
                        <p className="title-card">Velocidade</p>
                        <p className="text-cart">Agilize seus atendimentos ao máximo!</p>
                    </div>
                    <div className="card">
                        <BiGroup size={70} color="#000" />
                        <p className="title-card">Reorganização</p>
                        <p className="text-cart">Foque somente nos processos mais importantes.</p>
                    </div>
                    <div className="card">
                        <BiMoneyWithdraw size={60} color="#000" />
                        <p className="title-card">Controle</p>
                        <p className="text-cart">Saiba sobre tudo o que acontece dentro do seu negócio.</p>
                    </div>
                </div>
            </section>

            {/* CONTAINER: EXPLICAÇÃO APLICATIVO */}
            <section className="divided-container" id="explain-app-section">
                <div className="divided-area">
                    <img src={mockup_demo_menu_hud} className="image-mockup-app" />
                </div>

                <div className="divided-area" id="explain-app-text-area">
                    <h1 className="subtitle-explain-app">Todos os dados do seu negócio</h1>
                    <h1 className="title-explain-app">Na sua mão!</h1>

                    <div className="functionalities-area">
                        <div className="container-explain-functionalities">
                            <BiNotepad size={40} color="#000" />
                            <p className="explain-functionalities-text">Análise de pedidos</p>
                        </div>
                        <div className="container-explain-functionalities">
                            <IoBarChartOutline size={40} color="#000" />
                            <p className="explain-functionalities-text">Controle financeiro</p>
                        </div>
                        <div className="container-explain-functionalities">
                            <BiUserPin size={40} color="#000" />
                            <p className="explain-functionalities-text">Ferramentas de remarketing</p>
                        </div>
                        <div className="container-explain-functionalities">
                            <BiUniversalAccess size={40} color="#000" />
                            <p className="explain-functionalities-text">Controle de colaboradores</p>
                        </div>
                    </div>

                    <p className="conclusion-explain-app">Tudo feito pensando em maximizar seus ganhos e resultados e a satisfação dos seus clientes!</p>
                </div>
            </section>

            <section className="contact-area" id="contact">
                <div className="contact-cta-title-area">
                    <p className="subtitle-cta">Faça parte dessa</p>
                    <p className="title-cta">Revolução</p>
                </div>

                <div className="cta-text-buttons-group">
                    <p className="cta-text">
                        Envie-nos uma
                        <br />
                        mensagem
                    </p>
		    <a href="https://wa.me/16997091676?text=Olá,%20Tenho%20interesse%20em%20conhecer%20o%20SofChef.">
	    		<button className='whatsapp-button'>
                        	<FaWhatsapp size={40} color="#fff" />
                        	<p className='whatsapp-button-text'>Whatsapp</p>
                    	</button>
	    	    </a>
                </div>
            </section>

            <section className="about-us-area" id="about-us">
                <p className="about-us-title">Sobre nós</p>

                <p className="about-us-text">
                    A nossa missão é <b>levar seu estabelecimento a um novo patamar</b> de organização e controle, com uma solução <b>simples e intuitiva</b> tanto para você e seus colaboradores, quanto para aumentar o nível de experiência do seu cliente.
                    <br />
                    <br />É justamente por isso que trabalhamos para construir um sistema que agilize seus processos internos de atendimento. Tenha o seu negócio na <b>palma da sua mão</b>!<br />
                    <br />
                    Foque no que é realmente importante para o seu crescimento, o atendimento com a qualidade que será percebida pelo seus clientes.
                    <br />
                    <br />
                    <b>Venha para o SofChef!</b>
                </p>

                <img className="logo-about-us" src={require('../images/Logo.png')} />
            </section>

            <section className="bottom-menu">
                <div className="bottom-menu-content">
                    <div className="bottom-menu-links-area">
                        <ul className="bottom-links-area">
                            <li className="bottom-link">
                                <a href="#menu">
                                    <span>Início</span>
                                </a>
                            </li>
                            <li className="bottom-link">
                                <a href="#contact">
                                    <span>Contato</span>
                                </a>
                            </li>
                            <li className="bottom-link">
                                <a href="#about-us">
                                    <span>Sobre nós</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <img className="bottom-logo" src={require('../images/Logo-white.png')} />
                </div>
            </section>
        </div>

        // <div>
        //     <div className="overlay"></div>

        //     <div id="box-alert">
        //         <div>
        //             <h1 className="title-box">Site em construção!</h1>
        //             <p className="subtext-box">Em breve aqui a apresentação do sistema de controle do seu estabelecimento!</p>
        //         </div>

        //         <img src={Logo} className="logo-img" />
        //     </div>

        // </div>
    );
}

export default Home;
