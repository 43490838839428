import React, { useEffect } from 'react';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';


// STYLE LINKS
import './index.css';
import './css/components/scrollbar.css';


// SCREENS
import Home from './screens/home';
import Auth from './screens/auth';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>

                {/* <================== LANDING ROUTE ==================> */}
                <Route path="/" element={<Home />}/>

                {/* <================== AUTH ROUTE ==================> */}
                <Route path="/auth/:authToken/*" element={<Auth />} />

            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
);

reportWebVitals();
