import React, { useState } from 'react';
import { useEffect } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { Outlet, Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';


// STYLE LINKS ============================>
import '../css/screens/auth/auth.css';


// IMPORT ICONS
import { IoReload } from "react-icons/io5";


function Auth(){

    const { authToken } = useParams();

    const [status, setStatus] = useState(null);

    useEffect(() => {
        console.log(authToken);
        handleAuthenticateToken();
    }, [])

    const handleAuthenticateToken = async () => {
        try{
            const res = await axios.post('https://api.sofchef.com.br/auth/authenticate-account', {
                token: authToken,
            })

            console.log('RESPOSTA DA API:', res);

            setStatus(res.status);

        } catch (error) {
            console.error('ERROR - HANDLE AUTHENTICATE TOKEN:', error);
            setStatus(error.response.status);
        }
    }

    const AuthAlertBox = ({children}) => {
        return (
            <div className='alert-box'>
                <div className='children-area'>
                    {children}
                </div>

                <div className='image-area'>
                    <img className='logo-image' src={require('../images/Logo-white.png')}/>
                </div>
            </div>   
        )
    }

    return (
        <>
            {!status ? (
                <AuthAlertBox>
                    <div style={{alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <h1 className='title-box'>Estamos realizando sua autenticação</h1>
                        <div style={{marginLeft: 10, marginTop: 10}}>
                            <ThreeDots
                                visible={true}
                                height="30"
                                width="30"
                                color="#000"
                                radius="5"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
                    </div>
                </AuthAlertBox>

            )  : status == 200 ? (
                <AuthAlertBox>
                    <h1 className='title-box'>Sucesso</h1>
                    <p className='subtitle-page'>Sua conta foi registrada com sucesso em nosso sistema!&nbsp;Volte para a plataforma e realize login em sua conta!</p>

                </AuthAlertBox>

            ) : status == 400 ? (
                <AuthAlertBox>
                    <h1 className='title-box'>Token inválido</h1>
                    <p className='subtitle-page'>O token apresentado é inválido. Retorne ao e-mail enviado para sua conta e tente novamente.</p>
                </AuthAlertBox>

            ) : status == 401 ? (
                <AuthAlertBox>
                    <h1 className='title-box'>Token expirado</h1>
                    <p className='subtitle-page'>O token apresentado já foi expirado. Volte para a plataforma e envie o email novamente para prosseguir.</p>
                </AuthAlertBox>

            ) : (
                <AuthAlertBox>
                    <h1 className='title-box'>Erro</h1>
                    <p className='subtitle-page'>Tivemos um erro ao realizar sua autenticação. Clique no botão à baixo para tentar novamente.</p>
                    <button className='box-alert-button' onClick={() => window.location.reload()}>
                        {/* <spam style={{color: '#fff'}}>Tente novamente</spam> */}
                        <IoReload size={24} color={'#fff'}/>
                    </button>
                </AuthAlertBox>

            )}
        </>
    )
}

export default Auth;